<template>
  <div>
    <b-container class="base-container-x contact-header-box" style="min-height:500px;background-color:#fff">
       <b-skeleton-wrapper>
        <template>
          <div v-if="isLoading">
          <b-card class="border-0">
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
          </b-card>
          <b-card class="mt-3 border-0">
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
          </b-card>
          <b-card class="mt-3 border-0">
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
          </b-card>
          </div>
        </template>
        <b-row v-if="!isLoading && eventDetail">
          <b-col cols="12" class="py-3">
            <b-row>
              <b-col cols="3" col lg="1" sm="3" class="d-flex justify-content-center align-items-center pr-0">
                <div>
                  <b-avatar v-if="eventDetail.school" icon="card-image" size="3.5rem" :src="eventDetail.school_logo" variant="light"></b-avatar>
                  <b-avatar v-else icon="card-image" size="3.5rem" :src="eventDetail.teacher_avatar" variant="light"></b-avatar>
                </div>
              </b-col>
              <b-col cols="9" col lg="11" sm="9" class="d-block d-lg-flex align-items-center">
                <div>
                  <span v-if="eventDetail.school" class="font-weight-bold text-primary-dark mr-2 d-block d-lg-inline">{{ eventDetail.school_name }}</span>
                  <span v-else class="font-weight-bold text-primary-dark mr-2 d-block d-lg-inline">{{ eventDetail.teacher_name }}</span>
                  <span class="d-none d-lg-inline"> | </span>
                  <span class="text-danger-light ml-0 mx-lg-2"><font-awesome-icon :icon="['fas', 'map-marker-alt']" /> </span>
                  <span class="text-primary-dark"> {{ eventDetail.region_name }}, {{ eventDetail.region_country_name }} </span>
                  <span class="text-gray-light text-14 mx-2">{{ eventDetail.viewer | numeral('0,0') }} {{ $t('Viewers') }} </span>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col  cols="12" lg="6" sm="12">
              <b-img :src="eventDetail.image" rounded fluid-grow alt="Fluid-grow image"></b-img>
          </b-col>
          <b-col  cols="12" lg="6" sm="12" class="px-4 mt-3 mt-lg-0">
            <span class="text-primay-dark text-18 font-weight-bold">{{ eventDetail.subject }}</span>
            <b-row class="mt-3">
              <b-col cols="4" class="border-right text-center">
                <span class="text-primary-dark text-17"><b-icon icon="tag" flip-h/></span><br>
                <span class="text-danger-light text-18 font-weight-bold"><small>{{ eventDetail.currency }}</small> {{ eventDetail.price }}</span><br>
                <span class="text-gray-light text-16">{{ $t('Price') }} </span>
              </b-col>
              <b-col cols="4" class="border-right text-center">
                <span class="text-primary-dark text-17"><b-icon icon="calendar"/></span><br>
                <span class="text-danger-light text-18 font-weight-bold">{{  new Date(eventDetail.start_date) |  dateFormat('MMM DD', dateFormatConfig) }}</span><br>
                <span class="text-gray-light text-16">- {{  new Date(eventDetail.end_date) |  dateFormat('MMM DD', dateFormatConfig) }} </span>
              </b-col>
              <b-col cols="4" class="text-center">
                <span class="text-primary-dark text-17"><b-icon icon="clock"/></span><br>
                <span class="text-danger-light text-18 font-weight-bold">{{  new Date('1999-01-01T'+ eventDetail.start_time) | dateFormat('HH:mm A', dateFormatConfig) }}</span><br>
                <span class="text-gray-light text-16">- {{  new Date('1999-01-01T'+ eventDetail.end_time) | dateFormat('HH:mm A', dateFormatConfig) }} </span>
              </b-col>
            </b-row>
            <b-row class="my-3 px-2">
              <b-col cols="3" col lg="1" sm="3" class="d-flex justify-content-center align-items-center pr-0">
                <div>
                  <b-avatar size="3.5rem" variant="light"></b-avatar>
                </div>
              </b-col>
              <b-col cols="9" col lg="11" sm="9" class="d-block d-lg-flex align-items-center pl-4">
                <div>
                  <span v-if="eventDetail.school" class="font-weight-bold text-primary-dark mr-2 d-block">{{ eventDetail.instructors_name }}</span>
                  <span v-else class="font-weight-bold text-primary-dark mr-2 d-block">{{ eventDetail.teacher_name }}</span>
                  <span class="text-gray-light text-14">{{ $t('Instructor') }}</span>
                </div>
              </b-col>
            </b-row>
            <b-row class="my-3 px-2">
              <b-col cols="12" class="text-center">
                <b-button id="popover-share-button-sync" pill class="mt-2 mb-4 px-4 px-md-5 px-lg-5 px-xl-4 py-2 font-weight-bold btn-primary-dark d-none"><b-icon icon="reply-fill" class="mr-2"></b-icon>{{ $t('Share') }}</b-button>
                <b-button pill class="d-none mt-2 px-4 px-md-5 px-lg-5 px-xl-4 py-2 ml-0 ml-xl-3 ml-2 font-weight-bold btn-danger-light"><b-icon icon="heart-fill" class="mr-3"></b-icon>{{ $t('Like') }}</b-button>
                <b-popover boundary-padding="10" placement="auto" target="popover-share-button-sync" triggers="click blur">
                  <div>
                    <p>SHARE VIA</p>
                    <hr/>
                    <social-sharing :id="1" type="event" :metatag="metatag"/>
                  </div>
                </b-popover>
                <br>
                <b-button v-if="eventDetail && (schoolId == eventDetail.school || teacherId == eventDetail.teacher)" :to="{name: 'addEvent', query: { type: eventDetail.teacher ? 'teacher' : 'school' } }" pill class="btn mt-2 btn-danger-light d-inline font-weight-bold text-14 px-3 "><b-icon icon="plus" class="mr-2" variant="light"></b-icon>{{ $t('Add Training & Event') }}</b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="py-2">
          <b-col  cols="12" class="px-4">
            <span class="text-18 font-weight-bold text-primary-dark">{{ $t('Description') }}</span>
            <p class="text-gray-light text-14">
              {{ eventDetail.description }}
            </p>
          </b-col>
        </b-row>
        <b-row class="py-2">
          <b-col  cols="12" class="px-4">
            <span class="text-18 font-weight-bold text-primary-dark">{{ $t('Location') }}</span><br>
            <span class="text-danger-light ml-0 mx-lg-2"><font-awesome-icon :icon="['fas', 'map-marker-alt']" /> </span>
            <span class="text-primary-dark"> {{ eventDetail.region_name }}, {{ eventDetail.region_country_name }} </span>
          </b-col>
        </b-row>
        <b-row class="pt-2 mb-4">
          <b-col  cols="12" class="px-4">
            <span class="text-18 font-weight-bold text-primary-dark d-block">{{ $t('Contact') }}</span>
            <a v-if="eventDetail.website_url" :href="eventDetail.website_url" target="_blank"><b-button class="font-weight-bold rounded-circle btn-light border-0 mt-2 text-11" style="height:37px"><b-icon icon="globe2"/></b-button></a>
            <a v-if="eventDetail.facebook_url" :href="eventDetail.facebook_url" target="_blank"><b-button class="font-weight-bold rounded-circle social-graduate btn-light border-0 mt-2 ml-2"><font-awesome-icon :icon="['fab', 'facebook-f']" class="text-primary-dark"/></b-button></a>
            <a v-if="eventDetail.instagram_url" :href="eventDetail.instagram_url" target="_blank"><b-button class="font-weight-bold rounded-circle social-graduate btn-light border-0 mt-2 ml-2"><font-awesome-icon :icon="['fab', 'instagram']" class="text-primary-dark"/></b-button></a>
            <a v-if="eventDetail.twitter_url" :href="eventDetail.twitter_url" target="_blank"><b-button class="font-weight-bold rounded-circle social-graduate btn-light border-0 mt-2 ml-2"><font-awesome-icon :icon="['fab', 'twitter']" style="width:13px" class="text-primary-dark"/></b-button></a>
            <div class="pt-3">
              <a href="#" v-if="eventDetail.phone_number" class="text-14 text-primary-dark d-block"><b-icon icon="telephone-fill"/> {{ eventDetail.phone_number }}</a>
              <a :href="`mailto:${eventDetail.email}`" title="Email WYA Yoga Office" v-if="eventDetail.email" class="text-14 text-primary-dark d-block"><b-icon icon="envelope-fill"/> {{ eventDetail.email }}</a>
            </div>
          </b-col>
        </b-row>
       </b-skeleton-wrapper>
    </b-container>
  </div>
</template>
<script>
import Api from '../../api/api'
import SocialSharing from '../../components/SocialSharing.vue'

export default {
  name: 'eventDetail',
  title: ' | Add Training and Event',
  components: {
    SocialSharing
  },
  data () {
    return {
      isLoading: true,
      eventId: null,
      eventDetail: {},
      metatag: {}
    }
  },
  computed: {
    schoolId () {
      return this.$store.getters.schoolId
    },
    teacherId () {
      return this.$store.getters.teacherId
    }
  },
  mounted () {
    this.eventId = this.$route.params.eventId
    if (!this.eventId) {
      this.$router.push({ name: 'searchDirectory' })
    }
    this.event()
  },
  methods: {
    event () {
      this.isLoading = true
      Api.eventDetail({ id: this.eventId }).then(response => {
        if (response.data) {
          this.eventDetail = response.data
          this.metatag = {
            title: this.eventDetail.subject,
            description: this.eventDetail.description,
            quote: this.eventDetail.subject
          }
          this.isLoading = false
        }
      }).catch(() => {
        this.isLoading = false
      })
    }
  }
}
</script>
